<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <!-- BOC:[error] -->
    <AError
      v-if="api.isError"
      :api="api"
      :callbackReset="() => (api.isError = false)"
    ></AError>
    <!-- EOC -->
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <v-row>
        <v-col><h2>Delete User</h2></v-col>
      </v-row>
      <v-card-title></v-card-title>
      <div class="d-flex align-center justify-space-around">
        <div class="form-content text-center">
          <v-alert border="bottom" colored-border type="error" elevation="2">
            This action is irreversible. Are you sure you want to delete
            <b v-if="name">{{ name }}</b
            >?
          </v-alert>
          <div class="text-right">
            <v-btn color="error" class="mr-4" @click="submit" text> Yes </v-btn>
            <v-btn text @click="$router.go(-1)"> No </v-btn>
          </div>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: {
    size() {
      const size = { xs: "small", sm: "small", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  // computed: mapState({
  //     auth: (state) => state.auth.data,
  //   }),
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    deleteApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    name: null,
  }),
  created() {

    this.api.method = "GET";
    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/backend/user/" +
      this.$route.params.id;

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.name = resp.name;
      this.updateBreadcrumb(resp)
    };
    this.deleteApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/backend/user/delete";

    this.deleteApi.callbackReset = () => {
      this.deleteApi.isLoading = true;
      this.deleteApi.isError = false;
    };

    this.deleteApi.callbackError = (e) => {
      this.deleteApi.isLoading = false;
      this.deleteApi.isError = true;
      this.deleteApi.error = e;
    };

    this.deleteApi.callbackSuccess = () => {
      this.deleteApi.isLoading = false;
      this.$router.push({ name: "PageFamilyDashboard" });
    };
  },
  mounted() {
    this.$api.fetch(this.api);
  },
  methods: {
    updateBreadcrumb(resp) {
      this.breadcrumbs.push({
        text: "Dashboard",
        to: { name: "PageFamilyDashboard" },
        exact: true,
      });
     
      this.breadcrumbs.push({
      text: resp.name,
      to: { name: "PageFamilyUserRead",params:{id: this.$route.params.id} },
      exact: true,
    });

    this.breadcrumbs.push({
      text: 'Edit',
      to: { name: "PageFamilyUserDelete" },
      exact: true,
    });
    },
    submit() {
      this.deleteApi.params = {
        id: this.$route.params.id,
      };
      this.$api.fetch(this.deleteApi);
    },
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 600px !important;
}
</style>